import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Bio from "../components/bio"
import PostCard from "../components/postCard"
import ReactTooltip from "react-tooltip"
import Image from "gatsby-image"
// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Posts"
        keywords={[`devlog`, `blog`, `gatsby`, `javascript`, `react`]}
      />
      {/* <Bio /> */}
      <header className="page-head">
        <h2 className="page-head-title text-left">
          Hej.<br></br>
          Jestem Wojtek.<br></br>
          Jaram się projektowaniem i UX.<br></br>
          Od czasu do czasu śmigam na hackathony.<br></br>
          Piszę fronty (React, Gatsby, Vue).<br></br>
          Mam przyjemność powitać Cię na...<br></br>
          Kolejnym blogu o programowaniu w polskich internetach.<br></br>
          [ZOBACZ WIĘCEJ]👇<br></br>
          <span className="buzzwords">
            (Buzzwordy, żeby się lepiej klikało: Cloud, Python, Agile, Machine
            Learning, AI, Cyberpunk)
          </span>
        </h2>
      </header>
      {/* <ReactTooltip id="hi">
        <span>👋</span>
        </ReactTooltip>
        <ReactTooltip id='wojtek'>
          <div style={{width: '200px', height: '200px'}}>
          <Image fluid={data.image1.childImageSharp.fluid} />
          </div>
        </ReactTooltip>
        <ReactTooltip id='frameworks'>
          <div style={{width: '200px', height: '200px'}}>
          <Image fluid={data.image1.childImageSharp.fluid} />
          </div>
        </ReactTooltip>
        <ReactTooltip id='hackathon'>
          <div style={{width: '369px', height: '358px'}}>
          <Image fluid={data.image1.childImageSharp.fluid} />
          </div>
        </ReactTooltip> */}
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
    </Layout>
  )
}

// image1: file(relativePath: { eq: "index/design.png" }) {
//   childImageSharp {
//   fluid(maxWidth: 361) {
//     ...GatsbyImageSharpFluid
//   }
// }
// }
// image2: file(relativePath: { eq: "index/frameworks.png" }) {
//   childImageSharp {
//   fluid(maxWidth: 425) {
//     ...GatsbyImageSharpFluid
//   }
// }
// }
// image3: file(relativePath: { eq: "hackathon.png" }) {
//   childImageSharp {
//   fluid(maxWidth: 369) {
//     ...GatsbyImageSharpFluid
//   }
// }
// }

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM-DD-YYYY")
            title
            description
            tags
            useThumbnail
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
